
/*
    jquery.paginate
    ^^^^^^^^^^^^^^^

    Description: Add a pagination to everything.
    Version: Version 0.3.0
    Author: Kevin Eichhorn (https://github.com/neighbordog)
*/

(function( $ ) {

  $.paginate = function(element, options) {

      /*
          #Defaults
      */
      var defaults = {
          perPage:                3,              //how many items per page
          autoScroll:             true,           //boolean: scroll to top of the container if a user clicks on a pagination link
          scope:                  '',             //which elements to target
          paginatePosition:       ['bottom'],     //defines where the pagination will be displayed
          containerTag:           'nav',
          paginationTag:          'ul',
          itemTag:                'li',
          linkTag:                'a',
          useHashLocation:        true,           //Determines whether or not the plugin makes use of hash locations
          onPageClick:            function() {}   //Triggered when a pagination link is clicked

      };

      var plugin = this;
      var plugin_index = $('.paginate').length;

      plugin.settings = {};

      var $element = $(element);

      var curPage, items, offset, maxPage;

      /*
          #Initliazes plugin
      */
      plugin.init = function() {
          plugin.settings = $.extend({}, defaults, options);

          curPage = 1;
          items =  $element.children(plugin.settings.scope);
          maxPage = Math.ceil( items.length / plugin.settings.perPage ); //determines how many pages exist

          var paginationHTML = generatePagination(); //generate HTML for pageination

          if($.inArray('top', plugin.settings.paginatePosition) > -1) {
              $element.before(paginationHTML);
          }

          if($.inArray('bottom', plugin.settings.paginatePosition) > -1) {
              $element.after(paginationHTML);
          }

          $element.addClass("paginate");
          $element.addClass("paginate-" + plugin_index);

          var hash = location.hash.match(/\#paginate\-(\d)/i);

          //Check if URL has matching location hash
          if(hash && plugin.settings.useHashLocation) {
              plugin.switchPage(hash[1]);
          } else {
              plugin.switchPage(1); //go to initial page
          }

      };

      /*
          #Switch to Page > 'page'
      */
      plugin.switchPage = function(page) {

          if(page == "next") {
              page = curPage + 1;
          }

          if(page == "prev") {
              page = curPage - 1;
          }

          //If page is out of range return false
          if(page < 1 || page > maxPage) {
              return false;
          }

          if(page > maxPage) {
              $('.paginate-pagination-' + plugin_index).find('.page-next').addClass("deactive");
              return false;
          } else {
              $('.paginate-pagination-' + plugin_index).find('.page-next').removeClass("deactive");
          }

          $('.paginate-pagination-' + plugin_index).find('.active').removeClass('active');
          $('.paginate-pagination-' + plugin_index).find('.page-' + page).addClass('active');

          offset = (page - 1) * plugin.settings.perPage;

          $( items ).hide();

          //Display items of page
          for(i = 0; i < plugin.settings.perPage; i++) {
              if($( items[i + offset] ).length)
                  $( items[i + offset] ).fadeTo(100, 1);
          }

          //Deactive prev button
          if(page == 1) {
              $('.paginate-pagination-' + plugin_index).find('.page-prev').addClass("deactive");
          } else {
              $('.paginate-pagination-' + plugin_index).find('.page-prev').removeClass("deactive");
          }

          //Deactive next button
          if(page == maxPage) {
              $('.paginate-pagination-' + plugin_index).find('.page-next').addClass("deactive");
          } else {
              $('.paginate-pagination-' + plugin_index).find('.page-next').removeClass("deactive");
          }

          curPage = page;

          return curPage;

      };

      /*
      #Kills plugin
      */
      plugin.kill = function() {

          $( items ).show();
          $('.paginate-pagination-' + plugin_index).remove();
          $element.removeClass('paginate');
          $element.removeData('paginate');

      };

      /*
      #Generates HTML for pagination (nav)
      */
      var generatePagination = function() {

          var paginationEl = '<' + plugin.settings.containerTag + ' class="paginate-pagination paginate-pagination-' + plugin_index + '" data-parent="' + plugin_index + '">';
          paginationEl += '<' + plugin.settings.paginationTag + '>';

          paginationEl += '<' + plugin.settings.itemTag + '>';
          paginationEl += '<' + plugin.settings.linkTag + ' href="#" data-page="prev" class="page page-prev">&laquo;</' + plugin.settings.linkTag + '>';
          paginationEl += '</' + plugin.settings.itemTag + '>';

          for(i = 1; i <= maxPage; i++) {
              paginationEl += '<' + plugin.settings.itemTag + '>';
              paginationEl += '<' + plugin.settings.linkTag + ' href="#paginate-' + i + '" data-page="' + i + '" class="page page-' + i + '">' + i + '</' + plugin.settings.linkTag + '>';
              paginationEl += '</' + plugin.settings.itemTag + '>';
          }

          paginationEl += '<' + plugin.settings.itemTag + '>';
          paginationEl += '<' + plugin.settings.linkTag + ' href="#" data-page="next" class="page page-next">&raquo;</' + plugin.settings.linkTag + '>';
          paginationEl += '</' + plugin.settings.itemTag + '>';

          paginationEl += '</' + plugin.settings.paginationTag + '>';
          paginationEl += '</' + plugin.settings.containerTag + '>';

          //Adds event listener for the buttons
          $(document).on('click', '.paginate-pagination-' + plugin_index + ' .page', function(e) {
              e.preventDefault();

              var page = $(this).data('page');
              var paginateParent = $(this).parents('.paginate-pagination').data('parent');

              //Call onPageClick callback function
              $('.paginate-' + paginateParent).data('paginate').settings.onPageClick();

              page = $('.paginate-' + paginateParent).data('paginate').switchPage(page);

              if(page) {
                  if(plugin.settings.useHashLocation)
                      location.hash = '#paginate-' + page; //set location hash

                  if(plugin.settings.autoScroll)
                      $('html, body').animate({scrollTop: $('.paginate-' + paginateParent).offset().top}, 'slow');

              }

          });

          return paginationEl;

      };

      plugin.init();

  };

  $.fn.paginate = function(options) {

      return this.each(function() {
          if (undefined === $(this).data('paginate')) {
              var plugin = new $.paginate(this, options);
                  $(this).data('paginate', plugin);
          }
      });

  };

}( jQuery ));

$(document).ready(function(){
  var dashboard = $('#dashboard')
  var menuIcon = $('#dashboard #top .left .fas')
  var topLeft = $('#dashboard #top .left')
  var searchBox = $('#search-norme')
  var dashboard_menu_icon = $('#dashboard #top .left .fas')

  function toggleDashboard () {
    dashboard.toggleClass('open closed')
    menuIcon.toggleClass('fa-times fa-bars')
    if( dashboard.hasClass('closed') ) {
      topLeft.find('.title').html('IU')
    }
    else {
      topLeft.find('.title').html('IperTesto Unico')
    }
    if(dashboard.hasClass('closed') && searchBox.hasClass('expanded')){
      searchBox.toggleClass('expanded contract')
    }
    if(dashboard.hasClass('open') && searchBox.hasClass('contract')){
      searchBox.toggleClass('expanded contract')
    }
  }

  dashboard_menu_icon.click(toggleDashboard)

  window.innerWidth < 1001 &&
    window.location.search.search('ricerca_submitted') == -1 &&
    dashboard_menu_icon.click()

  $('#search .fa.fa-search').click(function(){
    if(dashboard.hasClass('closed')){
      toggleDashboard()
    }
    searchBox.toggleClass('expanded contract')
  })

  $('.search-results').paginate({
      perPage: 8,
      autoScroll: false,
    }
  );
})


$(document).ready(function(){
  $('body')
    .append('<div id="target"></div>')
    .append('<div id="goUp"><i class="fas fa-chevron-up"></i></div>')

  var goUp = $('#goUp')
  var target = document.getElementById("target")

  goUp.click(function(){
    $("html, body").animate({ scrollTop: 0 }, 500);
    return false;
  });

  function handler(entries) {
    for (entry of entries) {
      if (entry.isIntersecting) {
        goUp.addClass('show')
      } else {
        goUp.removeClass('show')
      }
    }
  }

  new IntersectionObserver(handler).observe(target)
})

/*
CSS Browser Selector 0.6.3
Originally written by Rafael Lima (http://rafael.adm.br)
http://rafael.adm.br/css_browser_selector
License: http://creativecommons.org/licenses/by/2.5/

Co-maintained by:
https://github.com/verbatim/css_browser_selector
*/
showLog=true;function log(m){if(window.console&&showLog)console.log(m)}
function css_browser_selector(u){var uaInfo={},screens=[320,480,640,768,1024,1152,1280,1440,1680,1920,2560],allScreens=screens.length,ua=u.toLowerCase(),is=function(t){return RegExp(t,"i").test(ua)},version=function(p,n){n=n.replace(".","_");var i=n.indexOf("_"),ver="";while(i>0){ver+=" "+p+n.substring(0,i);i=n.indexOf("_",i+1)}ver+=" "+p+n;return ver},g="gecko",w="webkit",c="chrome",f="firefox",s="safari",o="opera",m="mobile",a="android",bb="blackberry",lang="lang_",dv="device_",html=document.documentElement,
b=[!/opera|webtv/i.test(ua)&&/msie\s(\d+)/.test(ua)||/trident\/.*rv:([0-9]{1,}[\.0-9]{0,})/.test(ua)?"ie ie"+(/trident\/4\.0/.test(ua)?"8":RegExp.$1=="11.0"?"11":RegExp.$1):is("firefox/")?g+" "+f+(/firefox\/((\d+)(\.(\d+))(\.\d+)*)/.test(ua)?" "+f+RegExp.$2+" "+f+RegExp.$2+"_"+RegExp.$4:""):is("gecko/")?g:is("opera")?o+(/version\/((\d+)(\.(\d+))(\.\d+)*)/.test(ua)?" "+o+RegExp.$2+" "+o+RegExp.$2+"_"+RegExp.$4:/opera(\s|\/)(\d+)\.(\d+)/.test(ua)?" "+o+RegExp.$2+" "+o+RegExp.$2+"_"+RegExp.$3:""):is("konqueror")?
"konqueror":is("blackberry")?bb+(/Version\/(\d+)(\.(\d+)+)/i.test(ua)?" "+bb+RegExp.$1+" "+bb+RegExp.$1+RegExp.$2.replace(".","_"):/Blackberry ?(([0-9]+)([a-z]?))[\/|;]/gi.test(ua)?" "+bb+RegExp.$2+(RegExp.$3?" "+bb+RegExp.$2+RegExp.$3:""):""):is("android")?a+(/Version\/(\d+)(\.(\d+))+/i.test(ua)?" "+a+RegExp.$1+" "+a+RegExp.$1+RegExp.$2.replace(".","_"):"")+(/Android (.+); (.+) Build/i.test(ua)?" "+dv+RegExp.$2.replace(/ /g,"_").replace(/-/g,"_"):""):is("chrome")?w+" "+c+(/chrome\/((\d+)(\.(\d+))(\.\d+)*)/.test(ua)?
" "+c+RegExp.$2+(RegExp.$4>0?" "+c+RegExp.$2+"_"+RegExp.$4:""):""):is("iron")?w+" iron":is("applewebkit/")?w+" "+s+(/version\/((\d+)(\.(\d+))(\.\d+)*)/.test(ua)?" "+s+RegExp.$2+" "+s+RegExp.$2+RegExp.$3.replace(".","_"):/ Safari\/(\d+)/i.test(ua)?RegExp.$1=="419"||(RegExp.$1=="417"||(RegExp.$1=="416"||RegExp.$1=="412"))?" "+s+"2_0":RegExp.$1=="312"?" "+s+"1_3":RegExp.$1=="125"?" "+s+"1_2":RegExp.$1=="85"?" "+s+"1_0":"":""):is("mozilla/")?g:"",is("android|mobi|mobile|j2me|iphone|ipod|ipad|blackberry|playbook|kindle|silk")?
m:"",is("j2me")?"j2me":is("ipad|ipod|iphone")?(/CPU( iPhone)? OS (\d+[_|\.]\d+([_|\.]\d+)*)/i.test(ua)?"ios"+version("ios",RegExp.$2):"")+" "+(/(ip(ad|od|hone))/gi.test(ua)?RegExp.$1:""):is("playbook")?"playbook":is("kindle|silk")?"kindle":is("playbook")?"playbook":is("mac")?"mac"+(/mac os x ((\d+)[.|_](\d+))/.test(ua)?" mac"+RegExp.$2+" mac"+RegExp.$1.replace(".","_"):""):is("win")?"win"+(is("windows nt 6.2")?" win8":is("windows nt 6.1")?" win7":is("windows nt 6.0")?" vista":is("windows nt 5.2")||
is("windows nt 5.1")?" win_xp":is("windows nt 5.0")?" win_2k":is("windows nt 4.0")||is("WinNT4.0")?" win_nt":""):is("freebsd")?"freebsd":is("x11|linux")?"linux":"",/[; |\[](([a-z]{2})(\-[a-z]{2})?)[)|;|\]]/i.test(ua)?(lang+RegExp.$2).replace("-","_")+(RegExp.$3!=""?(" "+lang+RegExp.$1).replace("-","_"):""):"",is("ipad|iphone|ipod")&&!is("safari")?"ipad_app":""];console.debug(ua);function screenSize(){var w=window.outerWidth||html.clientWidth;var h=window.outerHeight||html.clientHeight;uaInfo.orientation=
w<h?"portrait":"landscape";html.className=html.className.replace(/ ?orientation_\w+/g,"").replace(/ [min|max|cl]+[w|h]_\d+/g,"");for(var i=allScreens-1;i>=0;i--)if(w>=screens[i]){uaInfo.maxw=screens[i];break}widthClasses="";for(var info in uaInfo)widthClasses+=" "+info+"_"+uaInfo[info];html.className=html.className+widthClasses;return widthClasses}window.onresize=screenSize;screenSize();function retina(){var r=window.devicePixelRatio>1;if(r)html.className+=" retina";else html.className+=" non-retina"}
retina();var cssbs=b.join(" ")+" js ";html.className=(cssbs+html.className.replace(/\b(no[-|_]?)?js\b/g,"")).replace(/^ /,"").replace(/ +/g," ");return cssbs}css_browser_selector(navigator.userAgent);